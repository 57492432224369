angular.module('angular-jplayer', []).directive('jplayer', [function() {

    var link = function(scope, elm) {
        var randomId = 'video' + (Math.random() * Math.pow(10, 20));
        $(elm).attr('id', randomId);

        $('#' + randomId + ' .player').jPlayer({
            solution: "html",
            cssSelectorAncestor: '#' + randomId,
            supplied: "m4v, webmv",
            useStateClassSkin: true,
            autoBlur: false,
            smoothPlayBar: true,
            keyEnabled: false,
            remainingDuration: true,
            toggleDuration: true,
            size: {
                width: '100%',
                height: '100%',
                cssClass: 'video'
            },
            play: function() {
                $(this).jPlayer("pauseOthers");
            }
        });

        var setMedia = function() {
            var media = {};
            if (scope.mp4) {
                media['m4v'] = scope.mp4;
            }

            if (scope.webm) {
                media['webm'] = scope.webm;
            }

            if (scope.cover) {
                media['poster'] = scope.cover;
            }

            $(elm).find('.player').jPlayer('setMedia', media);
        };

        scope.$watch('mp4', setMedia);
        scope.$watch('webm', setMedia);
        scope.$watch('cover', setMedia);
    };

    return {
        restrict: 'E',
        replace: true,
        templateUrl: '/angular-jplayer/jplayer.html',
        scope: {
            mp4: '=?',
            webm: '=?',
            wav: '=?',
            cover: '=?'
        },
        link: link
    }

}]);